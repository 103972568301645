<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 py-4 pl-12>
        <h2>Get started</h2>
      </v-flex>
      <v-flex xs12>
        <v-divider/>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center mt-4 mb-8>
      <v-flex xs11>
        <div v-if="!user.loggedIn">
          <h3 class="mt-6 mb-4">What you need to do</h3>
          <div class="mb-3">
            <v-icon class="black--text mr-2" style="margin-bottom: 2px">mdi-numeric-1-box-outline</v-icon>
            <b><span @click="$router.push('/signup')" class="textAsLink">Sign up</span> to get your free credits.</b>
          </div>
          <div class="mb-3">
            <v-icon class="black--text mr-2" style="margin-bottom: 2px">mdi-numeric-2-box-outline</v-icon>
            <b>Copy-paste the <span @click="$router.push('/code-snippet')" class="textAsLink">code snippet</span> to call our API.</b>
          </div>
          <div class="mb-3">
            <v-icon class="black--text mr-2" style="margin-bottom: 2px">mdi-numeric-3-box-outline</v-icon>
            <b>Get a subscription to switch to production.</b>
          </div>
        </div>

        <div v-if="user.loggedIn">
          <div class="mt-5 mb-2">
            <h3 class="mb-2">What you need to do</h3>
            <small>
              <b>
                <div class="mb-3">
                  <v-icon class="black--text mr-2" small style="margin-bottom: 2px">mdi-numeric-1-box-outline</v-icon>
                  <span>Copy-paste the <span @click="$router.push('/code-snippet')" class="textAsLink">code snippet</span> to call our API.</span>
                </div>
                <div class="mb-3">
                  <v-icon class="black--text mr-2" small style="margin-bottom: 2px">mdi-numeric-2-box-outline</v-icon>
                  <span><span @click="$router.push('/api-keys')" class="textAsLink">Copy your API key</span> to insert in the code snippet.</span>
                </div>
                <div class="mb-3">
                  <v-icon class="black--text mr-2" small style="margin-bottom: 2px">mdi-numeric-3-box-outline</v-icon>
                  <span>Use your free credits to test parsing CVs with the API.</span>
                </div>
                <div class="mb-3">
                  <v-icon class="black--text mr-2" small style="margin-bottom: 2px">mdi-numeric-4-box-outline</v-icon>
                  <span>In your code, map the extracted data fields to your own data structure.</span>
                </div>
                <div class="mb-3">
                  <v-icon class="black--text mr-2" small style="margin-bottom: 2px">mdi-numeric-5-box-outline</v-icon>
                  <span><span @click="$router.push('/pricing')" class="textAsLink">Get your subscription</span></span>
                </div>
              </b>
            </small>
          </div>
        </div>



      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-divider/>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center mt-4 mb-8>
      <v-flex xs11>
        <h3 class="mt-6 mb-1">How it works</h3>
        <small>
          <ul>
            <li>
              The code snippet is a simple https request that sends the CV to our API.
            </li>
            <li>
              The CV is parsed, and its extracted data is directly returned to you in the response of the https request.
            </li>
            <li>
              Store the extracted data in your own database, because we instantly delete it from our servers once the parsing is done.
            </li>
          </ul>
        </small>

        <h3 class="mt-6 mb-1">Details</h3>
        <small>
          <ul>
            <li>Accepted CV content: in any human language.</li>
            <li>Accepted CV formats: .pdf, .docx, .doc, .odt, .pptx, .ppt, .jpeg, .png.</li>
            <li>You can make multiple API calls in parallel to parse up to 500 CVs / minute.</li>
            <li>Contact us if you have this type of high volumes.</li>
          </ul>
        </small>

        <h3 class="mt-6 mb-1">Private & GDPR compliant</h3>
        <small>
          <div><b>In short:</b></div>
          <ul>
            <li>The CV is processed exclusively within the European Union.</li>
            <li>The CV and any trace of its data are deleted from our servers instantly when its parsing is done.</li>
          </ul>
          <div class="mt-3"><b>Here’s an overview our technical approach to GDPR compliance:</b></div>
          <div>
            <ul>
              <li>Built for secured private processing purpose only:</li>
              <ul>
                  <li>Our only usage of your data is to process it for you and return it to you.</li>
                  <li>We don’t share your data with anyone except our cloud providers.</li>
              </ul>
            
              <li class="mt-1">No storage:</li>
              <ul>
                <li>We delete the data instantly when the CV parsing is done.</li>
                <li>We don’t store the CV or its data longer than the time necessary to process it. Which means around 20 seconds.</li>
                <li>At the moment that we send the response to the https request made by your server, we delete every trace of it.</li>
                <li>There is no hosting: the extracted data is returned in the response to your https request, not hosted anywhere for you to fetch.</li>
              </ul>

              <li class="mt-1">Servers in the European Union only:</li>
              <ul>
                <li>Our cloud servers are only located in the EU regions of Google Cloud Platform.</li>
                <li>Your data never transits outside of the EU at any moment.</li>
              </ul>
            </ul>
          </div>
        </small>

        <h3 class="mt-6 mb-1">Legal terms</h3>
        <small>
          <ul>
            <li>
              <a href="/terms_and_conditions.html" target="_blank">Terms and conditions</a>
            </li>
            <li>
              <a href="/privacy_policy.html" target="_blank">Privacy policy</a>
            </li>
            <li>
              <a href="/cookie_policy.html" target="_blank">Cookie policy</a>
            </li>
          </ul>
        </small>

        <h3 class="mt-6 mb-1">Contact</h3>
        <small>
          <ul>
            <li>Feel free to send us an email at contact@cvparser.ai</li>
          </ul>
        </small>
      </v-flex>
    </v-layout>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  methods: {

  },

}
</script>